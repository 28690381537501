export const rankValueOptions = [
  {
    label: '$ values',
    value: 'value_dollar',
    format: 'currency', // this means we don't need to do comparisons in index.vue
  },
  {
    label: '% of Total for Projects',
    value: 'value_percent',
    format: 'percent:2',
  },
] as const

export const rankPresentationOptions = [
  {
    label: 'Rank',
    value: 'rank',
  },
  {
    label: 'Values',
    value: 'values',
  },
] as const

export const volumeFilterOptions = [
  {
    label: 'Maker & Taker',
    value: 'total',
  },
  {
    label: 'Maker Only',
    value: 'maker',
  },
  {
    label: 'Taker Only',
    value: 'taker',
  },
] as const

export const liquidityTypeOptions = [
  {
    label: 'Bid & Ask',
    value: 'bidAsk',
  },
  {
    label: 'Bid Only',
    value: 'bid',
  },
  {
    label: 'Ask Only',
    value: 'ask',
  },
] as const

export const depthLevelOptions = [
  {
    label: '2% Threshold',
    value: '200',
  },
  {
    label: '1% Threshold',
    value: '100',
  },
  {
    label: '0.5% Threshold',
    value: '50',
  },
] as const
