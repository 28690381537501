import type { PathMethodParams } from '#core/types/api'
import type { MMLeaderboardFilterByOptionEnumType } from '@forgd/shared/enums'
import type {
  EngagementOptionEnumType,
  ExchangeMcapTierEnumType,
  ExchangeTierEnumType,
} from '@forgd/supabase'
import { defineStore } from 'pinia'

// Filter metadata - move to a separate config file if grows larger
export const FILTER_SECTIONS = {
  businessModel: {
    name: 'Business Models',
    filters: {
      loanCallOption: {
        name: 'Loan + Call Option',
        value: 'loan_call_option' as EngagementOptionEnumType,
      },
      retainerWorkingCapital: {
        name: 'Retainer + Working Capital',
        value: 'retainer_working_capital' as EngagementOptionEnumType,
      },
    },
  },
  marketCap: {
    name: 'Project Valuation',
    filters: {
      market_cap_xl: {
        name: 'Major Market Cap: Larger than $1B',
        value: { from: 1000000000, to: Number.POSITIVE_INFINITY },
        id: 'major' as ExchangeMcapTierEnumType,
      },
      market_cap_lg: {
        name: 'Larger Market Cap: From $250M to $1B',
        value: { from: 250000000, to: 1000000000 },
        id: 'large' as ExchangeMcapTierEnumType,
      },
      market_cap_md: {
        name: 'Mid Market Cap: From $50M to $250M',
        value: { from: 50000000, to: 250000000 },
        id: 'mid' as ExchangeMcapTierEnumType,
      },
      market_cap_sm: {
        name: 'Low Market Cap: From $1M to $50M',
        value: { from: 1000000, to: 50000000 },
        id: 'low' as ExchangeMcapTierEnumType,
      },
      market_cap_xs: {
        name: 'Micro Market Cap: From $0 to $1M',
        value: { from: 0, to: 1000000 },
        id: 'micro' as ExchangeMcapTierEnumType,
      },
    },
  },
  exchangesTier: {
    name: 'CEX Coverage',
    filters: {
      exchangesTier1: {
        name: 'Tier 1 Exchanges',
        value: 1,
        id: 'tier_1' as ExchangeTierEnumType,
        images: ['CEX-Binance.jpg', 'CEX-OKX.jpg'],
      },
      exchangesTier2: {
        name: 'Tier 2 Exchanges',
        value: 10,
        id: 'tier_2' as ExchangeTierEnumType,
        images: ['CEX-Coinbase.jpg', 'CEX-ByBit.jpg', 'CEX-KuCoin.jpg', 'CEX-Gate-io.jpg'],
      },
      exchangesTier3: {
        name: 'Tier 3 Exchanges',
        value: 100,
        id: 'tier_3' as ExchangeTierEnumType,
      },
    },
  },
} as const

// Simple filter state type
type FilterState = Record<string, boolean>

type LeaderboardQuery = PathMethodParams<'/liquidity/market-makers-leaderboard'>['query']

export const useLeaderboardStore = defineStore('leaderboard', () => {
  // Global filter state
  const applyFiltersGlobally = ref(true)
  const filterRefreshedAt = ref(Date.now())

  // Rank presentation state
  const filterRankValue = ref(rankValueOptions[0])
  const filterRankPresentation = ref(rankPresentationOptions[0])

  // Market makers data state
  const filterLiquidityType = ref(liquidityTypeOptions[0])
  const filterDepth = ref(depthLevelOptions[1])
  const filterVolume = ref(volumeFilterOptions[0])

  // Initialize filter state with all true
  const filterState = ref<FilterState>(
    Object.fromEntries(
      Object.values(FILTER_SECTIONS).flatMap(section =>
        Object.keys(section.filters).map(key => [key, true]),
      ),
    ),
  )

  // Computed filter params
  const filterParams = computed<LeaderboardQuery>(() => {
    const exchangeTiers = Object.entries(FILTER_SECTIONS.exchangesTier.filters)
      .filter(([key]) => filterState.value[key])
      .map(([_, filter]) => filter.id)

    const businessModelFilters = Object.entries(FILTER_SECTIONS.businessModel.filters)
      .filter(([key]) => filterState.value[key])

    const marketCapFilters = Object.entries(FILTER_SECTIONS.marketCap.filters)
      .filter(([key]) => filterState.value[key])
      .map(([_, filter]) => filter.id)

    return {
      exchangeTiers: exchangeTiers.length > 0 ? exchangeTiers : undefined,
      fdvCategories: marketCapFilters.length > 0 ? marketCapFilters : undefined,
      engagementOption: businessModelFilters.length === 1
        ? businessModelFilters[0][1].value
        : undefined,
    }
  })

  // Market makers query
  const queryFilter = ref<MMLeaderboardFilterByOptionEnumType>('all')
  const { data: leaderboardData, loading: leaderboardDataLoading } = useQuery('/liquidity/market-makers-leaderboard', {
    params: () => ({
      query: {
        filter: queryFilter.value,
        ...filterParams.value,
        liquidityType: filterLiquidityType.value?.value,
        depthLevel: filterDepth.value?.value,
        volumeType: filterVolume.value?.value,
        rankValue: filterRankValue.value?.value,
        rankPresentation: filterRankPresentation.value?.value,
      },
    }),
  })

  const filteredData = computed(() => {
    if (!leaderboardData.value?.length) {
      return []
    }

    // Get inactive filters
    const inactiveFilters = Object.entries(filterState.value)
      .filter(([_, enabled]) => !enabled)
      .map(([key]) => key)

    if (inactiveFilters.length === 0) {
      return leaderboardData.value
    }

    return leaderboardData.value.filter((item) => {
      return inactiveFilters.every((key) => {
        // Find which section this filter belongs to
        for (const [sectionId, section] of Object.entries(FILTER_SECTIONS)) {
          if (!(key in section.filters)) {
            continue
          }

          switch (sectionId) {
            case 'businessModel': {
              type BusinessModelKey = keyof typeof FILTER_SECTIONS.businessModel.filters
              if (key in FILTER_SECTIONS.businessModel.filters) {
                const filter = FILTER_SECTIONS.businessModel.filters[key as BusinessModelKey]
                return !item.engagementOptions?.includes(filter.value)
              }
              break
            }
            case 'marketCap': {
              type MarketCapKey = keyof typeof FILTER_SECTIONS.marketCap.filters
              if (key in FILTER_SECTIONS.marketCap.filters) {
                const filter = FILTER_SECTIONS.marketCap.filters[key as MarketCapKey]
                return item.avgProjectFDV < filter.value.from || item.avgProjectFDV >= filter.value.to
              }
              break
            }
            case 'exchangesTier': {
              type ExchangeTierKey = keyof typeof FILTER_SECTIONS.exchangesTier.filters
              if (key in FILTER_SECTIONS.exchangesTier.filters) {
                const filter = FILTER_SECTIONS.exchangesTier.filters[key as ExchangeTierKey]
                const tierKey = `tier${filter.value}` as keyof typeof item
                const tierValue = item[tierKey] as number
                return tierValue === undefined || tierValue < filter.value
              }
              break
            }
          }
        }
        return true
      })
    })
  })

  function updateFilter(filterId: string, newValue: boolean) {
    const toast = useAppToast()
    const baseToast = useToast()
    baseToast.clear()

    // Find which section this filter belongs to
    let sectionId: keyof typeof FILTER_SECTIONS | undefined
    for (const [id, section] of Object.entries(FILTER_SECTIONS)) {
      if (filterId in section.filters) {
        sectionId = id as keyof typeof FILTER_SECTIONS
        break
      }
    }

    if (!sectionId) {
      return
    }

    const activeSectionFilters = Object.keys(FILTER_SECTIONS[sectionId].filters)
      .filter(key => filterState.value[key])
      .length

    if (activeSectionFilters <= 1 && !newValue) {
      toast.error({
        title: 'Review the filter configuration',
        description: 'Please ensure that in this category at least one option is selected.',
      })
      return
    }

    filterState.value[filterId] = newValue
    filterRefreshedAt.value = Date.now()
  }

  function resetFilters() {
    const baseToast = useToast()
    baseToast.clear()

    for (const key in filterState.value) {
      filterState.value[key] = true
    }
    filterRefreshedAt.value = Date.now()
  }

  return {
    // State
    applyFiltersGlobally,
    filterRefreshedAt,
    filterRankValue,
    filterRankPresentation,
    filterLiquidityType,
    filterDepth,
    filterVolume,
    filterState,
    leaderboardData,
    leaderboardDataLoading,
    queryFilter,
    filteredData,
    // Computed
    filterParams,
    // Actions
    setLeaderboardGlobalFilter(value: boolean) {
      applyFiltersGlobally.value = value
    },
    updateFilter,
    resetFilters,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useLeaderboardStore, import.meta.hot))
}
